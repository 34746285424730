import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { UserService } from "../services/user.service";
import { NotificationService } from '../notification/notification.service';
import * as XLSX from 'xlsx';
import { PagerService } from "../services/pager.service";
import { KycserviceService } from '../services/kycservice.service'
import { StorageService } from '../services/storage.service';
import { TranslateService } from '@ngx-translate/core';

import { AgGridAngular } from 'ag-grid-angular';
import * as moment from 'moment';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'app-collateral-deposit',
  templateUrl: './collateral-deposit.component.html',
  styleUrls: ['./collateral-deposit.component.css']
})
export class CollateralDepositComponent implements OnInit {

  //Order History Ag Grid
  public cyptoDepositHistoryColumnDefs: ColDef[] = [
    {
      field: 'createdAt', headerName: 'Timestamp (CET)', sort: 'desc', width: 170,
      valueFormatter: param => {
        if (param.value) {
          return moment(param.value).format('YYYY-MM-DD HH:mm:ss a');
        }
        return '';
      }
    },

    //{ field: 'txId', headerName: 'Date of Transaction (CET)', width: 300 },
    { field: 'txId', headerName: 'Txn Id', width: 250 },
    { field: 'amount', headerName: 'Amount', width: 120 },
    { field: 'multiplier', headerName: 'Multiplier', width: 120 },
    { field: 'multiplier_amount', headerName: 'Multiplier Amount', width: 200 },
    { field: 'currency', headerName: 'Currency', width: 120 },
    { field: 'note', headerName: 'Note', width: 300 },
  ];
  public cyptoDepositHistoryDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable:true,
    enableCellChangeFlash: true
  };
  public cyptoDepositHistoryRowData: any[] = [];
  @ViewChild(AgGridAngular) cyptoDepositHistoryAgGrid: AgGridAngular;
  cyptoDepositHistoryGridApi!: GridApi;

  constructor(
    private userService: UserService,
    private router: Router,
    private notifyService: NotificationService,
    private pagerService: PagerService,
    private kycService: KycserviceService,
    private storageService: StorageService,
    public translate: TranslateService

  ) {

    this.kycService.getUserKYC().subscribe(res => {
      if (res.status !== 'Approve') {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", "Pole juurdepääsetav enne KYC kinnitamist")
        } else {
          this.notifyService.showError("", "Not Accesible until KYC Verification")
        }
        this.router.navigate(['kyc'])
      }
    }, (err) => {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", "Pole juurdepääsetav enne KYC kinnitamist")
      } else {
        this.notifyService.showError("", "Not Accesible until KYC Verification")
      }
      this.router.navigate(['kyc'])
    })
  }
  public ledger: Array<any> = [];
  public data: any = [];
  public ledgerlist: any = [];
  public txnTypelistfilter: any = [];
  public typelistfilter: any = [];
  public selectedtypefilter: any = '';

  fileName: string;
  searchObj = {
    Currency: '',
    Type: '',
  }
  pager1: any = {};
  Tpager: number;
  pager: number;
  FilPage: number;
  FilPerpage: number;
  currentPage: number;

  currencylist = [];

  ngOnInit(): void {

    this.FilPerpage = 10000;
    this.FilPage = 1;
    this.pager = 1;

    this.data = {};

    this.csrf();

    this.txnTypelistfilter = [
      { txnType: "Trade" },
      { txnType: "Withdrawal" },
      { txnType: "Deposits" },
      { txnType: "Referral" },
      { txnType: "CreateWallet" },
    ]

    this.typelistfilter = [
      { type: "Type" },
      { type: "Currency" },
    ]
  }
  csrf() {
    this.userService.csrf().subscribe((response) => {
      this.data['_csrf'] = response._csrf;
      this.fetchLedger("", "");
    }, (err) => {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'Ilmnes viga, proovige uuesti.')
      } else {
        this.notifyService.showError("", 'Error occured, please try again.')
      }
    });
    this.userService.getProfileAPI().subscribe((response) => {
    });
  }

  dateformat(value) {
    return new DatePipe('en-Us').transform(value, 'dd-MM-yyyy HH:mm:ss', 'GMT+2');
  }

  fetchLedger(currency, txnType) {
    this.searchObj['Type'] = txnType ?? this.searchObj['Type'];
    this.searchObj['Currency'] = currency ?? this.searchObj['Currency'];
    this.userService.getCollateralDepositlistbyuserid(this.data, this.FilPerpage, this.FilPage, this.searchObj.Currency, this.searchObj.Type, false).subscribe((list) => {
      this.ledger = list.collateral_deposit;
      this.cyptoDepositHistoryRowData = list.collateral_deposit;
      this.ledgerlist = []
      this.ledger.forEach(element => {
        this.ledgerlist.push({
          'Date of Transaction': this.dateformat(element.createdAt),
          'TxnId': element.txId,
          'Multiplier': element.multiplier,
          'Multiplier Amount': element.multiplier_amount,
          'Currency': element.currency,
          'Amount': element.amount,
          'TxnType': element.type,
          'Notes': element.note
        })
      });

      this.currentPage = list.current ? list.current : 1;
      this.Tpager = list.pages;
      this.setPage(this.FilPage);
    })
  }

  exportExcel() {

    this.fileName = 'Ledger.xlsx'
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.ledgerlist);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  setPage(page: number) {
    // get pager object from service
    this.pager1 = this.pagerService.getPager(this.Tpager, page);
  }
  setPageOnClick(page: number) {
    this.pager = page;
    this.FilPage = this.pager;
    this.fetchLedger(this.searchObj.Currency, this.searchObj.Type);
  }
  changetypefilter(selectedtypefilter: any) {
    this.selectedtypefilter = selectedtypefilter;
    this.searchObj['Type'] = '';
    this.searchObj['Currency'] = '';
    this.fetchLedger("", "");
  }
  
  onCyptoHistoryGridReady(params: GridReadyEvent) {
    this.cyptoDepositHistoryGridApi = params.api;
    this.cyptoDepositHistoryGridApi.sizeColumnsToFit();
    this.cyptoDepositHistoryGridApi.setDomLayout('autoHeight');
  }

  getCyptoHistoryRowNodeId(data) {
    return data['_id']
  }


  applyCyptoDepositHistoryFilter(event: any) {
    this.cyptoDepositHistoryGridApi.setQuickFilter(event.target.value)
  }
}
